html {
  overflow: hidden;
}

.badge {
  padding: 0.05rem 0.35rem;
  font-size: 8px;
}
body{
  overflow:hidden;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white; 
}
 

/* Handle */
::-webkit-scrollbar-thumb {
  background: #001C42; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #001C42; 
}
.authlayoutbg {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/assets/images/authbackground.png');

  & > * {
    z-index: 100;
  }
}

.checkbox {
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 45px;
    height: 20px;
    background: #c4c4c4;
    display: block;
    border-radius: 100px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 5px;
      width: 12px;
      height: 12px;
      background: #fff;
      border-radius: 90px;
      transition: 0.3s;
    }
  }

  input:checked + label {
    background: #34a853;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
}

.bg-pattern {
  background-image: url('/assets/images/Doodle.svg');
  background-position: center;
  background-size: cover;
}

.dropshadow {
  box-shadow: 0px 0px 10px 0px #00000040;
}

.shadowcustom {
  box-shadow: 0px 12.419191837310791px 12.419191837310791px 0px #0000002b;
  box-shadow: 0px 0px 12.419191837310791px 0px #00000015;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, #111111 50%),
    linear-gradient(135deg, #111111 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  &:focus {
    background-image: linear-gradient(45deg, #001c43 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #001c43 50%);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    border-color: #001c43;
  }
}

.customfileinput {
  input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 22px;
    z-index: 20;
    width: 91%;
    cursor: pointer;
    font-size: 0;
  }
}

.schedulespan {
  @apply px-3.5 py-2 text-[10px] text-white uppercase  rounded-xl cursor-pointer;
}

.dropdownstyling {
  @apply first-letter:w-28  justify-between bg-lightergrey capitalize cursor-pointer text-[11px] md:text-xs border-darkergrey border rounded-lg px-4 py-2.5 text-center inline-flex items-center space-x-3;
}

.businesstypeinput {
  label {
    @apply border-2 border-[#6F6F6F] text-[#6F6F6F] inline-flex text-sm items-center px-6 py-2 font-bold rounded-md transition ease-in-out duration-300 cursor-pointer;
  }
  input {
    @apply hidden;
  }

  input:checked + label {
    @apply bg-primary transition duration-300 text-white border-0 py-2.5;
  }
}

// custom calender theme

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  a.fc-h-event {
    @apply bg-transparent border-0;
    .fc-event-main {
      @apply text-black whitespace-normal text-[8px] flex items-center;

      &::before {
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        margin: 0px 5px;
        border-radius: 50%;
      }
    }
  }
  .fc-toolbar-chunk {
    @apply flex items-center;
  }
  .fc-header-toolbar.fc-toolbar {
    @apply m-0;
    .fc-toolbar-title {
      @apply text-sm font-semibold;
    }
    .fc-button-primary {
      @apply bg-transparent border-0 text-black text-xs capitalize font-bold cursor-pointer;
      &:focus {
        @apply shadow-none opacity-40  outline-0;
      }
    }
  }

  .fc-scrollgrid {
    @apply border-0;
  }
  .fc-daygrid-day {
    @apply border-[10px] border-white border-solid font-semibold text-xs py-2 bg-[#e3e3e3] cursor-pointer;

    &.fc-day:not(.fc-day-today) {
      @apply text-[#C4C4C4] bg-[#eeeeee] text-left;
    }
  }
  td,
  th {
    @apply border-0 m-5;
  }
}

.fc-col-header-cell.fc-day {
  @apply mx-10 py-4  font-semibold text-xs;
}

.customscrollbar {
  &::-webkit-scrollbar {
    width: 12px;
    height: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 6px;
    background-color: #e7e7e7;
  }
}

.customradio,
.customcheckbox {
  position: relative;
  label {
    &::before {
      content: '';
      background: none;
      border: 1px solid #000;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      width: 15px;
      height: 15px;
      padding: 2px;
      left: -10px;
      margin-right: 10px;
      text-align: center;
      cursor: pointer;
    }
  }
  input {
    opacity: 0;
    left: 10px;
    cursor: pointer;
    position: absolute;
  }

  input:checked + label::before {
    content: '';
    background: url('https://api.iconify.design/akar-icons:check.svg') no-repeat
      center center / contain;
  }
}

.customcheckbox {
  label {
    &::before {
      width: 10px;
      height: 10px;
      margin-right: 4px;
    }
  }
}
