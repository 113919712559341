.carousel .control-dots {
  /* text-align: left!important;
    padding-left: 25%!important; */
}

.carousel,
.carousel-slider,
.carousel .slider-wrapper,
.slide,
.slider {
  @apply h-full;
}
.react-calendar {
  @apply border-none  font-medium dark:bg-[#262626];
  border-style: none !important;
}
.react-calendar__month-view__weekdays__weekday * {
  @apply no-underline font-[900];
  text-decoration-line: none !important;
}

.react-calendar__tile {
  @apply bg-white text-black dark:bg-[#262626]  dark:text-white;
}
.react-calendar__tile:hover * {
  @apply bg-[#e6e6e6] rounded-full py-2 px-[11px];
  background-color: #e6e6e6 !important;

}
.react-calendar__tile--active {
  @apply bg-white dark:bg-inherit;
  background-color: white !important;
}
.react-calendar__tile--active .dark .dark\:\!bg-inherit {
  background-color: inherit !important;
}
.react-calendar__tile--active * {
  @apply bg-black rounded-full py-2 px-[11px];
  background-color: black !important;
}
.react-calendar__tile--active:hover * {
  @apply bg-black rounded-full py-2 px-[11px] dark:text-white;
  background-color: black !important;
}
.react-calendar__tile:disabled {
  @apply bg-white  dark:bg-inherit text-gray-400 cursor-not-allowed;
  background-color: white !important;
}
.react-calendar__tile:disabled  .dark .dark\:\!bg-inherit {
  background-color: inherit !important;
}
.react-calendar__tile:hover {
  @apply rounded-full dark:text-black;
}
.react-calendar__navigation * {
  @apply dark:text-white;
}
.react-calendar__navigation *:disabled {
  @apply bg-inherit;
  background-color: inherit !important;
}
.carousel.carousel-slider .control-arrow {
  background-color: #251d2a !important;
} /* width */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}