@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Outfit");
@import url("https://fonts.googleapis.com/css?family=Inter:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./global.css";
@layer components {
  .apple-button {
    @apply bg-black text-white space-x-4 flex justify-center font-roboto font-medium;
  }

  .google-button {
    @apply bg-white text-gray-500 border border-solid border-gray-300 space-x-4 flex justify-center font-roboto font-medium;
  }
}

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.subscriptionFont{
  font-family:"Outfit"
}
.font-outfit{
  font-family:"Outfit"

}
.interFamily{
  font-family:"Inter"
}
.outfitFont {
  font-family:"Outfit"
}
// input[type=number]::-webkit-inner-spin-button, 
// input[type=number]::-webkit-outer-spin-button { 
//   -webkit-appearance: none; 
//   margin: 0; 
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fixedWidth {
  height: 1.5rem;
}

.text-truncater {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /*  max-height: 64px; */
}